import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/tailwind.css";
import "../public/css/style.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import "vue-multiselect/dist/vue-multiselect.css";
// import i18nPlugin from "./locale/i18nPlugin";
import { i18n } from "@/utils/i18n.js";
import { clickOutside } from "./utils/clickOutside.js";
import { createPinia } from "pinia";

const app = createApp(App);
const pinia = createPinia();
app.use(pinia); // Register Pinia

// app.use(i18nPlugin);
app.use(i18n);
app.use(router);
app.directive("click-outside", clickOutside);
app.mount("#app");
