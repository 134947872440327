import axios from "axios";

const API_URL = `${process.env.VUE_APP_BASE_API_URL}/skills/employees/by-skill-names`;

class SkillService {
  static async getEmployeesBySkills(name) {
    try {
      // Retrieve access token from local storage
      const token = localStorage.getItem("access_token");

      // Combine hardcoded fields with the dynamic `names`
      const skillsData = {
        id: 2, // Hardcoded ID
        name, // Dynamic skill names passed from the caller
        level: "Intermediate", // Hardcoded level
      };

      const response = await axios.post(API_URL, skillsData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Add the token in the Authorization header
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching employees by skills:", error);
      throw error;
    }
  }
}

export default SkillService;
