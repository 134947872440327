export function clickOutside(element, callback) {
  const handleClickOutside = (event) => {
    if (!(element === event.target || element.contains(event.target))) {
      callback(event);
    }
  };

  document.body.addEventListener("click", handleClickOutside);

  return () => {
    document.body.removeEventListener("click", handleClickOutside);
  };
}
