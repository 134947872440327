import axios from "axios";

const API_URL = `${process.env.VUE_APP_BASE_API_URL}/job-requests`; // Adding /job-request to the base URL

class JobRequestService {
  // Helper method to get the Authorization header
  static getAuthHeader() {
    const token = localStorage.getItem("access_token"); // Adjust this if your token key is different
    return {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
  }

  // Get all job requests
  static async getJobRequests() {
    try {
      const response = await axios.get(API_URL, {
        headers: this.getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching job requests:", error);
      throw error;
    }
  }

  // Get a job request by ID
  static async getJobRequestById(id) {
    try {
      const response = await axios.get(`${API_URL}/${id}`, {
        headers: this.getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      console.error(`Error fetching job request with ID ${id}:`, error);
      throw error;
    }
  }

  // Create a new job request
  static async createJobRequest(jobRequestData) {
    try {
      const response = await axios.post(API_URL, jobRequestData, {
        headers: this.getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      console.error("Error creating job request:", error);
      throw error;
    }
  }

  // Update a job request by ID
  static async updateJobRequest(id, updatedData) {
    try {
      const response = await axios.put(`${API_URL}/${id}`, updatedData, {
        headers: this.getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      console.error(`Error updating job request with ID ${id}:`, error);
      throw error;
    }
  }

  // Delete a job request by ID
  static async deleteJobRequest(id) {
    try {
      const response = await axios.delete(`${API_URL}/${id}`, {
        headers: this.getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      console.error(`Error deleting job request with ID ${id}:`, error);
      throw error;
    }
  }

  // Create a new application for a specific job request
  static async createApplication(jobRequestId, applicationData) {
    try {
      const response = await axios.post(
        `${API_URL}/${jobRequestId}/applications`,
        applicationData,
        {
          headers: this.getAuthHeader(),
        }
      );
      return response.data;
    } catch (error) {
      console.error(
        `Error creating application for job request ID ${jobRequestId}:`,
        error
      );
      throw error;
    }
  }

  // Get applications by job request ID
  static async getApplicationsByJobRequestId(jobRequestId) {
    try {
      const response = await axios.get(
        `${API_URL}/${jobRequestId}/applications`,
        {
          headers: this.getAuthHeader(),
        }
      );
      return response.data;
    } catch (error) {
      console.error(
        `Error fetching applications for job request ID ${jobRequestId}:`,
        error
      );
      throw error;
    }
  }
}

export default JobRequestService;
