import { createI18n } from "vue-i18n";
import en from "@/locale/en";
import jp from "@/locale/jp";

const messages = {
	EN: en,
	JP: jp,
};

const i18n = createI18n({
	legacy: false,
	locale: localStorage.getItem("language") || "EN",
	fallbackLocale: "EN",
	messages,
});

const setLanguage = (code = "EN") => {
	i18n.global.locale.value = code;
	localStorage.setItem("language", code);
};

const detectLanguage = () => {
	const userLang = navigator.language || navigator.userLanguage;
	let langCode = "EN";

	if (userLang.includes("ja")) {
		langCode = "JP";
	}

	setLanguage(langCode);
};

if (localStorage.getItem("language")) {
	setLanguage(localStorage.getItem("language"));
} else {
	detectLanguage();
}

export { i18n, setLanguage, detectLanguage };
