<script>
import JobRequestService from '@/services/JopRequestServices';
import SkillService from '@/services/SkillServices';
import EmailService from '@/services/EmailServices';
import CustomBreadcrumbs from '@/components/breadcrumbs/CustomBreadcrumbs.vue';
import Sidebar from '@/components/sidebars/Sidebar.vue'


export default {
    components: {
        CustomBreadcrumbs,
        Sidebar
    },
    data() {
        return {
            formData: {
                title: '',
                description: '',
                duration: 1, // Default value set to 1 month
                required_skills: '', // Skills can be comma-separated
                client_id: null, // Client ID should be a number
            },
            showAlert: false,
            alertMessage: '',
            alertType: '',
            skillApiResult: [], // Array to hold the results from the Skill API
            selectedEmployees: [], // Array to hold selected employees
            selectAll: false, // For handling "select all" functionality
        };
    },
    methods: {
        async submitForm() {
            const formattedData = {
                ...this.formData,
                required_skills: this.formData.required_skills.split(',').map(skill => skill.trim()), // Convert skills string to array
            };

            try {
                // First, create the job request
                const jobResponse = await JobRequestService.createJobRequest(formattedData);
                console.log("Job Request Created:", jobResponse);

                // After the job request is successfully created, call the second API to fetch employees by skill names
                const employeesResponse = await SkillService.getEmployeesBySkills(formattedData.required_skills);
                console.log("Employees fetched based on skills:", employeesResponse);

                // Assign employees data correctly from the response
                this.skillApiResult = employeesResponse.data; // Correctly accessing the `data` array from the API response

                this.showAlert = true;
                this.alertMessage = 'Job request created and employees fetched successfully!';
                this.alertType = 'success';

            } catch (error) {
                // Handle any errors
                console.error("Error occurred:", error);
                this.showAlert = true;
                this.alertMessage = 'Error creating job request or fetching employees.';
                this.alertType = 'error';
            }
        },
        cancel() {
            // Reset form or handle cancel
            this.formData = {
                title: '',
                description: '',
                duration: 1,
                required_skills: '',
                client_id: null,
            };
            this.skillApiResult = []; // Clear the results on cancel
        },
        toggleSelectAll() {
            if (this.selectAll) {
                // Select all employees
                this.selectedEmployees = this.skillApiResult.map(employee => employee.name);
            } else {
                // Deselect all employees
                this.selectedEmployees = [];
            }
        },
        async sendEmail() {
            try {
                const emailPayload = {
                    employees: this.selectedEmployees,
                    clientId: this.formData.client_id, // Use the client ID from the form
                };
                const token = localStorage.getItem('access_token'); // Assuming token is stored in local storage

                // Send email using the EmailService
                await EmailService.sendEmail(token, emailPayload);
                this.showAlert = true;
                this.alertMessage = 'Email sent successfully!';
                this.alertType = 'success';
            } catch (error) {
                console.error('Error sending email:', error);
                this.showAlert = true;
                this.alertMessage = 'Error sending email.';
                this.alertType = 'error';
            }
        }
    },
};
</script>

<template>

    <div class="flex w-full">
        <!-- Sidebar Component -->
        <Sidebar />
        <div class="flex-1 p-6">
            <CustomBreadcrumbs :crumbs="[
                { name: 'Home', path: '/home' },
                { name: 'Mts', path: '/mts' },
                { name: 'New Job Request', path: '', isLink: false },
            ]" />
            <div class="grid grid-cols-2 p-4 w-full justify-start gap-4">
                <!-- Form Section -->
                <div>

                    <Alert v-if="showAlert" :type="alertType" :message="alertMessage" />
                    <form @submit.prevent="submitForm">
                        <h1 class="text-4xl font-bold mb-4">Create New Job Request</h1>

                        <div class="mb-4">
                            <label for="title" class="block text-lg text-gray-700">Job Title</label>
                            <input id="title" v-model="formData.title" class="input input-bordered w-full"
                                placeholder="Enter job title" />
                        </div>

                        <div class="mb-4">
                            <label for="description" class="block text-lg text-gray-700">Job Description</label>
                            <textarea id="description" v-model="formData.description"
                                class="input input-bordered w-full" placeholder="Enter job description"></textarea>
                        </div>

                        <div class="my-4">
                            <label for="duration" class="block text-lg text-gray-700">Job Duration (Months)</label>
                            <input id="duration" type="number" v-model="formData.duration"
                                class="input input-bordered w-full" placeholder="Duration in months" />
                        </div>

                        <div class="my-4">
                            <label for="required_skills" class="block text-lg text-gray-700">Required Skills</label>
                            <input id="required_skills" v-model="formData.required_skills"
                                class="input input-bordered w-full"
                                placeholder="Enter required skills (comma-separated)" />
                        </div>

                        <div class="my-4">
                            <label for="client_id" class="block text-lg text-gray-700">Client ID</label>
                            <input id="client_id" type="number" v-model="formData.client_id"
                                class="input input-bordered w-full" placeholder="Enter client ID" />
                        </div>

                        <hr class="border-t border-gray-300 my-4" />

                        <div class="flex justify-end mt-4">
                            <div class="w-1/2 flex justify-end space-x-2">
                                <button type="button" class="w-full btn btn-error text-white px-4 py-2 rounded"
                                    @click="cancel">
                                    Cancel
                                </button>
                                <button type="submit" class="w-full btn btn-primary text-white px-4 py-2 rounded">
                                    Create
                                </button>
                            </div>
                        </div>
                    </form>
                </div>

                <!-- Result Section -->
                <div>
                    <h2 class="text-2xl font-bold mb-4">Skill API Results</h2>
                    <div v-if="skillApiResult.length">
                        <!-- Select All checkbox -->
                        <div class="mb-4">
                            <input type="checkbox" v-model="selectAll" @change="toggleSelectAll" />
                            <label class="ml-2">Select All</label>
                        </div>

                        <ul>
                            <li v-for="(employee) in skillApiResult" :key="employee.id" class="mb-4 border p-2 rounded">
                                <input type="checkbox" v-model="selectedEmployees" :value="employee.name"
                                    class="mr-2" />
                                <label>{{ employee.name }}</label>
                                <p>Position ID: {{ employee.position_id }}</p>
                                <p>Daily Man Hours: {{ employee.daily_man_hours }}</p>

                                <div>
                                    <h4 class="font-semibold mt-2">Skills:</h4>
                                    <ul>
                                        <li v-for="skill in employee.skills" :key="skill.id">
                                            <strong>{{ skill.name }}</strong> - {{ skill.level }}<br />
                                            <span>{{ skill.description }}</span>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>

                        <!-- Send Email Button -->
                        <button @click="sendEmail" class="btn btn-success mt-4">Send Email</button>
                    </div>
                    <div v-else>
                        <p>No results to display</p>
                    </div>
                </div>
            </div>
        </div>

    </div>


</template>


<style scoped></style>