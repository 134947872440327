<template>
    <div class="w-64 h-screen bg-gray-800 text-white p-4">
        <ul class="space-y-4">
            <li v-for="(item, index) in menuItems" :key="index">
                <router-link :to="item.path" class="hover:bg-gray-700 p-2 block">
                    {{ item.label }}
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "Sidebar",
    props: {
        menuItems: {
            type: Array,
            required: true,
            default: () => []
        }
    }
};
</script>

<style scoped>
/* You can add custom styles for the sidebar here */
</style>