<template>
    <div class="breadcrumbs text-sm">
        <ul class="inline-flex items-center space-x-2">
            <li v-for="(crumb, index) in props.crumbs" :key="index" class="flex items-center">
                <template v-if="crumb.isLink !== false">
                    <router-link :to="crumb.path">
                        <a>{{ crumb.name }}</a>
                    </router-link>
                </template>
                <template v-else>
                    <span>{{ crumb.name }}</span>
                </template>
            </li>
        </ul>
    </div>
</template>

<script setup>
const props = defineProps({
    crumbs: {
        type: Array,
        required: true,
    }
});
</script>