import axios from "axios";

const API_URL = `${process.env.VUE_APP_BASE_API_URL}/client/email/send`;

class EmailService {
  static async sendEmail(authToken, emailPayload) {
    try {
      const response = await axios.post(API_URL, emailPayload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`, // Authorization with the token
        },
      });
      return response.data; // Return the response data
    } catch (error) {
      console.error("Error sending email:", error);
      throw error; // Throw error to handle it in the calling component
    }
  }
}

export default EmailService;
