
export function authGuard(to, from, next) {
  const accessToken = localStorage.getItem('access_token');
  
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!accessToken) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next();
  }
}